<template>
	<div class="wrapper_main footer-fixed">
		<main class="content">
			<div class="content__header align-end-md flex-xs-col">
				<div class="content__header-left  flex-md-col no-wrap flex-fill">
					<h1 class="heading-page-h1">{{ $translate('qr-scanner') }}</h1>
				</div>
			</div>
            <div class="content__body">
                <div class="addition footer-fixed">
                    <div class="headline-wrapper flex items-center mb-15">
                        <div class="headline headline--sm flex-auto">
                            <h2 class="headline__title">{{ $translate('qr-code-scanner') }}</h2>
                            <p class="headline__text">{{ $translate('here-you-can-scan-your-enismaro-qr-code') }}</p>
                        </div> 
                        <!-- <button type="button" class="btn btn-w-shadow btn-standart-2">
                            <span>Add Device</span>
                        </button> -->
                    </div> 
                    <div v-show="scannerStart" style="position: relative;">
                        <video ref="scaner_video_elem" class="scaner_video_elem"></video>
                    </div>
                    <p v-if="qrCamResult" class="scan_result_text">
                        <span v-if="thisIsLink(qrCamResult)">
                            {{ $translate('result') }}: <a :href="qrCamResult" target="_blank" class="btn btn-w-shadow btn-standart-2" style="margin-left: 10px;">{{ $translate('see-your-result') }}</a>
                        </span>
                        <span v-else>{{ `Result: ${ qrCamResult }` }}</span>
                    </p>
                    <button type="button" class="btn btn-w-shadow btn-standart-2" @click="videoScan(!scannerStart)" v-if="checkCamera">
                        <span>{{ scannerStart ? $translate('stop-cam-scanner') : $translate('start-cam-scanner') }}</span>
                    </button>
                    <button type="button" class="btn btn-w-shadow btn-standart-2" @click="qrCamResult = null" v-if="qrCamResult">
                        <span>{{ $translate('clear-cam-scanner-result') }}</span>
                    </button>
                    <div style="width: 100%" v-if="imageUploadSrc">
                        <img :src="imageUploadSrc" alt=""  class="scanner_img_elem" style="border-radius: 0;">
                    </div>
                    <p v-if="errorText" class="scan_result_text" style="color: red;">
                        {{ $translate('error') }}: {{ errorText }}
                    </p>
                    <p v-if="qrFileResult" class="scan_result_text">
                        <span v-if="thisIsLink(qrFileResult)">
                            {{ $translate('result') }}: <a :href="qrFileResult" target="_blank" class="btn btn-w-shadow btn-standart-2" style="margin-left: 10px;">{{ $translate('see-your-result') }}</a>
                        </span>
                        <span v-else>{{ `Result: ${ qrFileResult }` }}</span>
                    </p>
                    <label class="btn btn-w-shadow btn-standart-2">
                        <!--  {{ $translate('file') }} -->
                        <span>{{ $translate('scan-from--file') }} {{ imageUploadSrc ? $translate('new') : '' }}</span>
                        <input type="file" name="" id="" v-show="false" @change="scanFromFileChange">
                    </label>
                    <button type="button" class="btn btn-w-shadow btn-standart-2" v-if="imageUploadSrc" @click="removeUploadedFile">
                        <span>{{ $translate('remove-uploaded-file') }}</span>
                    </button>
                </div>
            </div>
		</main>
	</div>	
</template>

<script>
import Base from '@/components/base';
import QrScanner from 'qr-scanner';

export default {
	components: {
		...Base,
	},
	data() {
		return {
            qrScanner: null,
            checkCamera: false,
            scannerStart: false,
            qrCamResult: null,
            qrFileResult: null,
            imageUploadSrc: null,
            errorText: null,
		}
	},
	methods: {
        thisIsLink(scanRes){
            return scanRes ? scanRes.includes('/qr-mobile?') || scanRes.includes('http') || scanRes.includes('.com') : false
        },
        videoScan(start){
            this.scannerStart = start;
            this.errorText = null;

            if(start){
                this.qrCamResult = null;
                this.qrScanner.start();
            } else {
                this.qrScanner.stop();
            }
        },
        scanFromFileChange(e){
            this.qrFileResult = null;
            this.errorText = null;
            var tgt = e.target, files = tgt.files;
            let self = this;

            if (FileReader && files && files.length) {
                var fr = new FileReader();
                fr.onload = function () {
                    self.imageUploadSrc = fr.result;
                }
                fr.readAsDataURL(files[0]);
            }

            setTimeout(() => {
                QrScanner.scanImage(self.imageUploadSrc).then(result => {
                    self.qrFileResult = result;
                }).catch(error => {
                    self.errorText = error || 'No QR code found.';
                });
            }, 1000);
        },
        removeUploadedFile(){
            this.qrFileResult = null;
            this.imageUploadSrc = null;
            this.errorText = null;
        }
	},
    async mounted(){
        let self = this;
        this.qrScanner = new QrScanner(this.$refs.scaner_video_elem, result => {
            // console.log('decoded qr code:', result);
            self.qrCamResult = result.data;
        }, {
            highlightScanRegion: true,
            highlightCodeOutline: true,
            returnDetailedScanResult: true,
        });
        this.qrScanner.setInversionMode('both');
        this.checkCamera = await QrScanner.hasCamera();
    }
}
</script>

<style>
.scanner_img_elem,
.scaner_video_elem {
    height: 300px;
    border-radius: 8px;
    margin: 15px 0;
    overflow: hidden;
    border: 1px solid #efefef;
}
.scan_result_text {
    max-width: 1000px;
    display: block;
    word-break: break-all;
    margin-bottom: 15px;
    color: #9DB0B7;
}
.result_qr_link {
    text-decoration: underline;
    color: var(--text-primary);
}
</style>